html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

.video-card:hover {
    box-shadow: '0 0 25px 2px #FFFFFF';
    background: 'none';
    scale: '1.05';
}
/* @media (min-height: 1080px) {
    #introVideo {
        max-height: 100%;
    }
}

@media (max-height: 1080px) {
    #introVideo {
        max-width: 100%;
    }
} */

/* @media (min-height: 800px) {
    #introVideo {
        max-height: 100%;
    }
}

@media (max-height: 800px) {
    #introVideo {
        max-width: 100%;
    }
} */

@font-face {
    font-family: 'Goldman-Regular';
    src: local('Goldman-Regular'),
        url(./fonts/Goldman/Goldman-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'KaushanScript-Regular';
    src: local('KaushanScript-Regular'),
        url(./fonts/Kaushan_Script/KaushanScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Bangers';
    src: local('Bangers'),
        url(./fonts/Bangers/Bangers-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Cinzel';
    src: local('Cinzel'),
        url(./fonts/Cinzel/Cinzel-VariableFont_wght.ttf) format('truetype');
}
